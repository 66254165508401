.circle-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  /* same as image width */
  height: 150px;
  /* same as image height */
  border-radius: 50%;
  background-color: #9d44b5;
  /* set your desired background color */
  border: 2px solid #6290c3;
  /* set your desired outer frame color */
  overflow: hidden;
}

.circle-frame-web {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  /* same as image width */
  height: 300px;
  /* same as image height */
  border-radius: 50%;
  background-color: #cfd186;
  /* set your desired background color */
  border: 5px solid #9a48d0;
  /* set your desired outer frame color */
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 20px;
}

.circle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content {
  font-size: 13px;
  line-height: 1.8em;
  font-weight: 500;
}

a,
b {
  color: #9d44b5;
}

/* Base notification styling */
.notification,
.notification-error {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smooth fade-in and movement */
}

/* Success notification */
.notification {
  background-color: green;
  color: white;
}

/* Error notification */
.notification-error {
  background-color: red;
  color: white;
}

/* Show notification */
.notification.show,
.notification-error.show {
  opacity: 1; /* Make visible */
  transform: translateX(-50%) translateY(0); /* Bring into view */
}

/* Centered loading spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  flex-direction: column;
  text-align: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error message styling */
.error-container {
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.error-message {
  color: red;
  font-size: 18px;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
}

.blogView img {
  max-width: 100%; /* Ensures the image doesn't overflow its container */
  height: auto; /* Maintains the aspect ratio */
  display: block; /* Removes potential inline spacing */
  margin: 0 auto; /* Optional: Center the image if needed */
}

.blog-card {
  cursor: pointer; /* Change the cursor to a pointer */
}

.blog-card:hover {
  transform: translateY(-2px); /* Optional: Adds a small lift effect on hover */
  transition: transform 0.2s ease; /* Smooth transition for hover effect */
}
